import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/about.scss'

const About = () => {
  return (
    <Layout>
      <SEO
        title="Lumineers Are Prescribed by Licensed Dentists | Fabricated by DenMat Lab USA"
        description="Lumineers are prescribed by licensed dentists and fabricated exclusively by DenMat Lab in Lompoc, California, USA, by technicians that specialize in crafting esthetic dental veneers."
      />
      <Container>
        <Row className="mb-5">
          <Col md="12">
            <h1 className="mt-5">
              Lumineers<sup>®</sup> Are Prescribed by Licensed Dentists
            </h1>
            <p>
              Lumineers<sup>®</sup> are not available for purchase directly from
              the DenMat Lab or any other source. Lumineers dental veneers are
              only prescribed and placed by a licensed dentist. Lumineers are
              placed by dentists trained in painless minimally invasive
              techniques that typically require no shots or aggressive drilling.
              If your teeth are stained, crooked, misaligned, misshapen, have
              gaps or you are simply unhappy with they way they look, ask your
              dentist if Lumineers may be the right choice for you.
            </p>
            <p>
              Lumineers are fabricated exclusively by the DenMat Lab in Lompoc,
              California, USA, by technicians that specialize in crafting
              esthetic dental veneers. Ask your dentist to prescribe authentic
              Lumineers from DenMat Lab whenever veneers are indicated.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h2>Made in the USA</h2>
            <div class="embed-responsive embed-responsive-16by9 mb-5">
              <iframe
                src="https://www.youtube.com/embed/dOLFg2XW4iQ"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Lumineers Alanta"
              ></iframe>
            </div>
            <p>
              DenMat operates a state-of-the-art facility at its worldwide
              headquarters in Lompoc, California, USA, and specializes in the
              manufacture of a wide range of high-quality dental products, small
              equipment, and custom laboratory-fabricated dental restorations.
              Our restorative dental products are time-tested by thousands of
              our dentist customers and we are proud to be a part of your
              journey in oral health.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h2 className="mt-5 mb-5 text-center">More from DenMat</h2>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xl="4">
            <div className="text-center">
              <h2 className="site-selection">
                <a
                  href="https://www.oralprocare.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  OralProCare.com
                </a>
              </h2>
              <p>Dentist recommended at-home oral hygiene products</p>
              <a
                href="https://www.oralprocare.com"
                rel="noreferrer"
                target="_blank"
              >
                <div className="d-flex justify-content-center">
                  <StaticImage
                    src="../images/Oral-ProCare-logo-2-250x100.png"
                    alt="Oral ProCare Logo"
                    placeholder="none"
                    layout="fixed"
                    width={250}
                    height={100}
                  />
                </div>
              </a>
            </div>
          </Col>
          <Col xl="4">
            <div className="text-center">
              <h2 className="site-selection">
                <a
                  href="https://www.snaponsmile.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  SnapOnSmile.com
                </a>
              </h2>
              <p>Dentist prescribed affordable cosmetic removable arch</p>
              <a
                href="https://www.snaponsmile.com"
                rel="noreferrer"
                target="_blank"
              >
                <div className="d-flex justify-content-center">
                  <StaticImage
                    src="../images/Snap-On-Smile-logo-250x150.png"
                    alt="Snap-On Smile Logo"
                    placeholder="none"
                    layout="fixed"
                    width={250}
                    height={150}
                  />
                </div>
              </a>
            </div>
          </Col>
          <Col xl="4">
            <div className="text-center">
              <h2 className="site-selection">
                <a
                  href="https://www.ddslocator.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  DDSLocator.com
                </a>
              </h2>
              <p>Get more patients with the DDSLocator directory</p>
              <a
                href="https://www.ddslocator.com"
                rel="noreferrer"
                target="_blank"
              >
                <div className="d-flex justify-content-center">
                  <StaticImage
                    src="../images/DDSLocator-logo-horiz-250x60.jpg"
                    alt="DDSLocator Logo"
                    placeholder="none"
                    layout="fixed"
                    width={250}
                    height={60}
                  />
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default About
